import * as echarts from "echarts";
var sex_ratioData = [
  { value: 0, name: "男" },
  { value: 0, name: "女" },
];
export const sex_ratio = {
  title: {
    text: "男女比例",
    bottom: "20",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  tooltip: {
    trigger: "item",
    formatter: function (record) {
      return `${record.name} \n ${record.value}%`;
    },
  },
  legend: {
    bottom: "20%",
    left: "center",
    formatter: function (name) {
      // 在这里编写自定义的格式化逻辑
      let value;
      for (let i = 0; i < sex_ratioData.length; i++) {
        // value = grade_ratioData[i].value;
        // console.log(i, name);
        if (name == sex_ratioData[i].name) {
          return name + sex_ratioData[i].value + "%";
        }
      }
    },
  },
  series: [
    {
      name: "男女比例",
      type: "pie",
      radius: ["50%", "70%"],
      center: ["50%", "35%"],
      avoidLabelOverlap: false,
      color: ["#5D77F9", "#FF46B3"],
      label: {
        show: false,
        position: "center",
      },
      emphasis: {
        label: {
          show: false,
        },
      },
      labelLine: {
        show: false,
      },
      data: sex_ratioData,
    },
  ],
};

var one_hour_after_classData = [
  { value: 0, name: "参与" },
  { value: 0, name: "未参与" },
];
export const one_hour_after_class = {
  title: {
    text: "课后一小时落实率",
    bottom: "20",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  tooltip: {
    trigger: "item",
    formatter: function (record) {
      return `${record.name} \n ${record.value}%`;
    },
  },
  legend: {
    bottom: "20%",
    left: "center",
    formatter: function (name) {
      // 在这里编写自定义的格式化逻辑
      let value;
      for (let i = 0; i < one_hour_after_classData.length; i++) {
        // value = grade_ratioData[i].value;
        // console.log(i, name);
        if (name == one_hour_after_classData[i].name) {
          return name + one_hour_after_classData[i].value + "%";
        }
      }
    },
  },
  series: [
    {
      name: "课后一小时落实率",
      type: "pie",
      radius: ["50%", "70%"],
      center: ["50%", "35%"],
      avoidLabelOverlap: false,
      color: [
        "#5D77F9",
        "#84B7F9",
        "#E5F3FF",
        "#5F9CF8",
        "#E5F3FF",
        "#90B5FF",
        "#5087EC",
        "#2478F2",
        "#8BD0DB",
        "#67B3E8",
        "#6DC8E1",
        "#B6E3F0",
      ],
      label: {
        show: false,
        position: "center",
      },
      emphasis: {
        label: {
          show: false,
          fontSize: 40,
          fontWeight: "bold",
        },
      },
      labelLine: {
        show: false,
      },
      data: one_hour_after_classData,
    },
  ],
};

export const number_service = {
  title: {
    text: "社会服务机构报名人数 ",
    bottom: "20",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  tooltip: {
    trigger: "item",
  },
  legend: {
    bottom: "20%",
    left: "center",
    // width: "10",
  },
  series: [
    {
      name: "社会服务机构报名人数",
      type: "pie",
      radius: "75%",
      center: ["50%", "35%"],
      data: [
        // { value: 0, name: "篮球" },
        // { value: 0, name: "足球" },
        // { value: 0, name: "排球" },
      ],
      color: [
        "#5D77F9",
        "#84B7F9",
        "#E5F3FF",
        "#5F9CF8",
        "#E5F3FF",
        "#90B5FF",
        "#5087EC",
        "#2478F2",
        "#8BD0DB",
        "#67B3E8",
        "#6DC8E1",
        "#B6E3F0",
      ],
      labelLine: {
        show: false,
      },
      label: {
        show: false,
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

export const number_of_school_teams = {
  title: {
    text: "校队人数",
    bottom: "20",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  tooltip: {
    trigger: "item",
  },
  legend: {
    bottom: "20%",
    left: "center",
    // width: "10",
  },
  series: [
    {
      name: "校队人数",
      type: "pie",
      radius: "75%",
      center: ["50%", "35%"],
      data: [
        // { value: 0, name: "篮球" },
        // { value: 0, name: "足球" },
        // { value: 0, name: "排球" },
      ],
      color: [
        "#5D77F9",
        "#84B7F9",
        "#E5F3FF",
        "#5F9CF8",
        "#E5F3FF",
        "#90B5FF",
        "#5087EC",
        "#2478F2",
        "#8BD0DB",
        "#67B3E8",
        "#6DC8E1",
        "#B6E3F0",
      ],
      labelLine: {
        show: false,
      },
      label: {
        show: false,
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

export const number_of_winners = {
  title: {
    text: "比赛获奖人数",
    bottom: "20",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  tooltip: {
    trigger: "item",
  },
  legend: {
    bottom: "20%",
    left: "center",
    // width: "10",
  },
  series: [
    {
      name: "比赛获奖人数",
      type: "pie",
      radius: "75%",
      center: ["50%", "35%"],
      data: [],
      color: [
        "#5D77F9",
        "#84B7F9",
        "#E5F3FF",
        "#5F9CF8",
        "#E5F3FF",
        "#90B5FF",
        "#5087EC",
        "#2478F2",
        "#8BD0DB",
        "#67B3E8",
        "#6DC8E1",
        "#B6E3F0",
      ],
      labelLine: {
        show: false,
      },
      label: {
        show: false,
      },

      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

export const evaluation = {
  radar: {
    // shape: 'circle',
    indicator: [
      { name: "运动能力", max: 120 },
      { name: "体质健康", max: 120 },
      { name: "运动行为", max: 120 },
      { name: "体育知识", max: 120 },
    ],
    name: {
      rich: {
        a: {
          color: "#444",
          lineHeight: 10,
        },
      },
      formatter: (a, b) => {
        // return `{a|${a}}\n{b|${b.max}%}`;
        return `{a|${a}}`;
      },
    },
  },
  color: [
    "#7AC3CB",
    "#2478F2",
    "#5D77F9",
    "#A8DBCE",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  series: [
    {
      name: "运动能力",
      type: "radar",
      data: [],
    },
  ],
};

export const health = {
  legend: {
    top: "5%",
  },
  grid: {
    top: "10%",
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  tooltip: {},
  color: [
    "#5D77F9",
    "#A8DBCE",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  dataset: {
    source: [
      ["product", "优秀率", "合格率"],
      // ["2022年", 0, 0],
      // ["2023年", 0, 0],
    ],
  },
  xAxis: { type: "category" },
  yAxis: {
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  // Declare several bar series, each will be mapped
  // to a column of dataset.source by default.
  series: [
    {
      type: "bar",
      label: {
        show: true,
        color: "#5D77F9",
        position: "top",
        formatter: (value) => {
          return value.value[1] + "%";
        },
      },
    },
    {
      type: "bar",
      label: {
        show: true,
        color: "#A8DBCE",
        position: "top",
        formatter: (value) => {
          return value.value[2] + "%";
        },
      },
    },
  ],
};
export const health_all = {
  title: {
    text: "体质健康双达标变化趋势",
    bottom: "2%",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  legend: {
    top: "5%",
  },
  grid: {
    top: "10%",
    left: "3%",
    right: "4%",
    bottom: "12%",
    containLabel: true,
  },
  tooltip: {},
  color: [
    "#5D77F9",
    "#A8DBCE",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  dataset: {
    source: [
      ["product", "优秀率", "合格率"],
      // ["2022年", 0, 0],
      // ["2023年", 0, 0],
    ],
  },
  xAxis: { type: "category" },
  yAxis: {
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  // Declare several bar series, each will be mapped
  // to a column of dataset.source by default.
  series: [
    {
      type: "bar",
      label: {
        show: true,
        color: "#5D77F9",
        position: "top",
        formatter: (value) => {
          return value.value[1] + "%";
        },
      },
    },
    {
      type: "bar",
      label: {
        show: true,
        color: "#A8DBCE",
        position: "top",
        formatter: (value) => {
          return value.value[2] + "%";
        },
      },
    },
  ],
};

export const health_student = {
  tooltip: {
    trigger: "axis",
    axisPointer: {
      // Use axis to trigger tooltip
      type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
    },
  },
  title: {
    text: "体质健康成绩分布",
    bottom: "2%",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  legend: { top: "3%" },
  grid: {
    top: "12%",
    left: "3%",
    right: "4%",
    bottom: "12%",
    containLabel: true,
  },
  xAxis: {
    type: "value",
  },
  yAxis: {
    type: "category",
    data: [],
  },
  series: [
    {
      name: "不及格率",
      type: "bar",
      stack: "total",
      label: {
        show: true,
        color: "#fff",
        formatter: (value) => {
          return value.value + "%";
        },
      },
      emphasis: {
        focus: "series",
      },
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      color: "#FF635F",
    },
    {
      name: "及格率",
      type: "bar",
      stack: "total",
      label: {
        show: true,
        color: "#fff",
        formatter: (value) => {
          return value.value + "%";
        },
      },
      emphasis: {
        focus: "series",
      },
      color: "#F3C457",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: "良好率",
      type: "bar",
      stack: "total",
      label: {
        show: true,
        color: "#fff",
        formatter: (value) => {
          return value.value + "%";
        },
      },
      emphasis: {
        focus: "series",
      },
      color: "#2EAC3F",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: "优秀率",
      type: "bar",
      stack: "total",
      label: {
        show: true,
        color: "#fff",
        formatter: (value) => {
          return value.value + "%";
        },
      },

      emphasis: {
        focus: "series",
      },
      color: "#488CD1",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ],
};

export const health_student_all = {
  tooltip: {
    trigger: "axis",
    axisPointer: {
      // Use axis to trigger tooltip
      type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
    },
  },
  title: {
    text: "体质健康成绩分布（2022-2023学年）",
    bottom: "2%",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  legend: { top: "3%" },
  grid: {
    top: "12%",
    left: "3%",
    right: "4%",
    bottom: "12%",
    containLabel: true,
  },
  xAxis: {
    type: "value",
  },
  yAxis: {
    type: "category",
    data: [],
  },
  series: [
    {
      name: "不及格率",
      type: "bar",
      stack: "total",
      label: {
        show: true,
        color: "#fff",
        formatter: (value) => {
          return value.value + "%";
        },
      },
      emphasis: {
        focus: "series",
      },
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      color: "#FF635F",
    },
    {
      name: "及格率",
      type: "bar",
      stack: "total",
      label: {
        show: true,
        color: "#fff",
        formatter: (value) => {
          return value.value + "%";
        },
      },
      emphasis: {
        focus: "series",
      },
      color: "#F3C457",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: "良好率",
      type: "bar",
      stack: "total",
      label: {
        show: true,
        color: "#fff",
        formatter: (value) => {
          return value.value + "%";
        },
      },
      emphasis: {
        focus: "series",
      },
      color: "#2EAC3F",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: "优秀率",
      type: "bar",
      stack: "total",
      label: {
        show: true,
        color: "#fff",
        formatter: (value) => {
          return value.value + "%";
        },
      },

      emphasis: {
        focus: "series",
      },
      color: "#488CD1",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ],
};

export const attendanceStudentSchool_all = {
  title: {
    text: "体质健康双达标学校TOP10(2022-2023学年)",
    bottom: "5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    formatter: function (record) {
      return `${record[0].name} \n ${record[0].value}人`;
    },
  },
  grid: {
    top: "5%",
    left: "2%",
    right: "6%",
    bottom: "15%",
    containLabel: true,
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  xAxis: {
    type: "value",
    boundaryGap: [0, 0.01],
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  yAxis: {
    type: "category",
    data: [
      "xxx学校1",
      "xxx学校2",
      "xxx学校3",
      "xxx学校4",
      "xxx学校5",
      "xxx学校6",
      "xxx学校7",
      "xxx学校8",
      "xxx学校9",
      "xxx学校10",
    ],
  },
  series: [
    {
      name: "大课间出勤率学校TOP10（2022-2023学年）",
      type: "bar",
      label: {
        show: true,
        position: "right",
        color: "#226BFF",
        formatter: function (record) {
          return `${record.value}%`;
        },
      },
      data: [1, 2, 3, 5, 6, 7, 8, 9, 10, 11],
    },
  ],
};

export const one_hour = {
  title: {
    text: "体育课出勤",
    bottom: "-5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: ["2022-2023学年", "2021-2022学年"],
    top: "5%",
  },
  grid: {
    top: "10%",
    left: "3%",
    right: "4%",
    bottom: "5%",
    containLabel: true,
  },

  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [],
  },
  yAxis: {
    type: "value",
    // show: false,
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  color: [
    "#226BFF",
    "#68BBC4",
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  series: [
    {
      name: "2022-2023学年",
      type: "line",
      stack: "Total",
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        show: true,
        color: "#226BFF",
        formatter: function (params) {
          return params.value + "%";
        },
      },
    },
    {
      name: "2021-2022学年",
      type: "line",
      stack: "Total",
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        show: true,
        color: "#68BBC4",
        formatter: function (params) {
          return params.value + "%";
        },
      },
    },
  ],
};

export const one_hour_student = {
  title: {
    text: "体育课出勤率分布",
    bottom: "-5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "item",
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  series: [
    {
      name: "体育课出勤率分布",
      type: "pie",
      radius: "70%",
      data: [
        { value: 0, name: "一年级" },
        { value: 0, name: "二年级" },
        { value: 0, name: "三年级" },
        { value: 0, name: "四年级" },
        { value: 0, name: "五年级" },
        { value: 0, name: "六年级" },
        { value: 0, name: "七年级" },
        { value: 0, name: "八年级" },
        { value: 0, name: "九年级" },
      ],
      label: {
        show: true,
        textStyle: {
          color: "#444444",
          fontSize: 10,
          lineHeight: 14,
        },
        formatter: function (record) {
          return `${record.name} \n ${record.value}%`;
        },
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

export const one_hour_student_All = {
  title: {
    text: "体育课出勤率学校TOP10（2022-2023学年）",
    bottom: "5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    formatter: function (record) {
      return `${record[0].name} \n ${record[0].value}人`;
    },
  },
  grid: {
    top: "5%",
    left: "2%",
    right: "6%",
    bottom: "15%",
    containLabel: true,
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  xAxis: {
    type: "value",
    boundaryGap: [0, 0.01],
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  yAxis: {
    type: "category",
    data: [
      "xxx学校1",
      "xxx学校2",
      "xxx学校3",
      "xxx学校4",
      "xxx学校5",
      "xxx学校6",
      "xxx学校7",
      "xxx学校8",
      "xxx学校9",
      "xxx学校10",
    ],
  },
  series: [
    {
      name: "体育课出勤率学校TOP10（2022-2023学年）",
      type: "bar",
      label: {
        show: true,
        position: "right",
        color: "#226BFF",
        formatter: function (record) {
          return `${record.value}%`;
        },
      },
      data: [1, 2, 3, 5, 6, 7, 8, 9, 10, 11],
    },
  ],
};

export const during_break_time = {
  title: {
    text: "大课间出勤",
    bottom: "-5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: ["2022-2023学年", "2021-2022学年"],
    top: "5%",
  },
  grid: {
    top: "10%",
    left: "3%",
    right: "4%",
    bottom: "5%",
    containLabel: true,
  },

  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [],
  },
  yAxis: {
    type: "value",
    // show: false,
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  color: ["#226BFF", "#68BBC4"],
  series: [
    {
      name: "2022-2023学年",
      type: "line",
      stack: "Total",
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        show: true,
        color: "#226BFF",
        formatter: function (params) {
          return params.value + "%";
        },
      },
    },
    {
      name: "2021-2022学年",
      type: "line",
      stack: "Total",
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        show: true,
        color: "#68BBC4",
        formatter: function (params) {
          return params.value + "%";
        },
      },
    },
  ],
};
export const during_break_time_student = {
  title: {
    text: "大课间出勤率分布",
    bottom: "-5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "item",
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  series: [
    {
      name: "大课间出勤率分布",
      type: "pie",
      radius: "70%",
      data: [
        { value: 0, name: "一年级" },
        { value: 0, name: "二年级" },
        { value: 0, name: "三年级" },
        { value: 0, name: "四年级" },
        { value: 0, name: "五年级" },
        { value: 0, name: "六年级" },
        { value: 0, name: "七年级" },
        { value: 0, name: "八年级" },
        { value: 0, name: "九年级" },
        { value: 0, name: "高一" },
        { value: 0, name: "高二" },
        { value: 0, name: "高三" },
      ],
      label: {
        show: true,
        textStyle: {
          color: "#444444",
          fontSize: 10,
          lineHeight: 14,
        },
        formatter: function (record) {
          return `${record.name} \n ${record.value}%`;
        },
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};
export const during_break_time_student_All = {
  title: {
    text: "大课间出勤率学校TOP10（2022-2023学年）",
    bottom: "5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    formatter: function (record) {
      return `${record[0].name} \n ${record[0].value}人`;
    },
  },
  grid: {
    top: "5%",
    left: "2%",
    right: "6%",
    bottom: "15%",
    containLabel: true,
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  xAxis: {
    type: "value",
    boundaryGap: [0, 0.01],
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  yAxis: {
    type: "category",
    data: [
      "xxx学校1",
      "xxx学校2",
      "xxx学校3",
      "xxx学校4",
      "xxx学校5",
      "xxx学校6",
      "xxx学校7",
      "xxx学校8",
      "xxx学校9",
      "xxx学校10",
    ],
  },
  series: [
    {
      name: "大课间出勤率学校TOP10（2022-2023学年）",
      type: "bar",
      label: {
        show: true,
        position: "right",
        color: "#226BFF",
        formatter: function (record) {
          return `${record.value}%`;
        },
      },
      data: [1, 2, 3, 5, 6, 7, 8, 9, 10, 11],
    },
  ],
};

export const homework = {
  title: {
    text: "家庭作业（体育运动）完成率",
    bottom: "-5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: ["2022-2023学年", "2021-2022学年"],
    top: "5%",
  },
  grid: {
    top: "10%",
    left: "3%",
    right: "4%",
    bottom: "5%",
    containLabel: true,
  },

  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [],
  },
  yAxis: {
    type: "value",
    // show: false,
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  color: [
    "#226BFF",
    "#68BBC4",
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  series: [
    {
      name: "2022-2023学年",
      type: "line",
      stack: "Total",
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        show: true,
        color: "#226BFF",
        formatter: function (params) {
          return params.value + "%";
        },
      },
    },
    {
      name: "2021-2022学年",
      type: "line",
      stack: "Total",
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        show: true,
        color: "#68BBC4",
        formatter: function (params) {
          return params.value + "%";
        },
      },
    },
  ],
};
export const homework_student = {
  title: {
    text: "家庭作业类型占比（2022-2023学年）",
    bottom: "-5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "item",
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  series: [
    {
      name: "家庭作业类型占比",
      type: "pie",
      radius: "70%",
      data: [],
      label: {
        show: true,
        textStyle: {
          color: "#444444",
          fontSize: 10,
          lineHeight: 14,
        },
        formatter: function (record) {
          return `${record.name} \n ${record.value}%`;
        },
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

export const homework_knowledge = {
  title: {
    text: "家庭作业（知识素养）完成率",
    bottom: "-5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: ["2022-2023学年", "2021-2022学年"],
    top: "5%",
  },
  grid: {
    top: "10%",
    left: "3%",
    right: "4%",
    bottom: "5%",
    containLabel: true,
  },

  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [],
  },
  yAxis: {
    type: "value",
    // show: false,
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  color: [
    "#226BFF",
    "#68BBC4",
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  series: [
    {
      name: "2022-2023学年",
      type: "line",
      stack: "Total",
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        show: true,
        color: "#226BFF",
        formatter: function (params) {
          return params.value + "%";
        },
      },
    },
    {
      name: "2021-2022学年",
      type: "line",
      stack: "Total",
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        show: true,
        color: "#68BBC4",
        formatter: function (params) {
          return params.value + "%";
        },
      },
    },
  ],
};
export const homework_knowledge_student = {
  title: {
    text: "家庭作业完成率占比",
    bottom: "-5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "item",
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  series: [
    {
      name: "家庭作业类型占比",
      type: "pie",
      radius: "70%",
      data: [],
      label: {
        show: true,
        textStyle: {
          color: "#444444",
          fontSize: 10,
          lineHeight: 14,
        },
        formatter: function (record) {
          return `${record.name} \n ${record.value}%`;
        },
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

export const homework_knowledge_studentAll = {
  title: {
    text: "家庭作业完成率学校TOP(2022-2023学年)",
    bottom: "5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    formatter: function (record) {
      return `${record[0].name} \n ${record[0].value}人`;
    },
  },
  grid: {
    top: "5%",
    left: "2%",
    right: "6%",
    bottom: "15%",
    containLabel: true,
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  xAxis: {
    type: "value",
    boundaryGap: [0, 0.01],
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  yAxis: {
    type: "category",
    data: [
      "xxx学校1",
      "xxx学校2",
      "xxx学校3",
      "xxx学校4",
      "xxx学校5",
      "xxx学校6",
      "xxx学校7",
      "xxx学校8",
      "xxx学校9",
      "xxx学校10",
    ],
  },
  series: [
    {
      name: "大课间出勤率学校TOP10（2022-2023学年）",
      type: "bar",
      label: {
        show: true,
        position: "right",
        color: "#226BFF",
        formatter: function (record) {
          return `${record.value}%`;
        },
      },
      data: [1, 2, 3, 5, 6, 7, 8, 9, 10, 11],
    },
  ],
};

export const sports_art = {
  title: {
    text: "",
    bottom: "5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: ["2022-2023学年", "2021-2022学年"],
    top: "0%",
  },
  grid: {
    top: "5%",
    left: "3%",
    right: "4%",
    bottom: "10%",
    containLabel: true,
  },

  xAxis: {
    type: "category",
    boundaryGap: false,
    data: ["7月", "8月", "9月", "10月", "11月", "12月", "1月"],
  },
  yAxis: {
    type: "value",
    // show: false,
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  color: ["#226BFF", "#68BBC4"],
  series: [
    {
      name: "2022-2023学年",
      type: "line",
      stack: "Total",
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        show: true,
        color: "#226BFF",
        formatter: function (params) {
          return params.value + "%";
        },
      },
    },
    {
      name: "2021-2022学年",
      type: "line",
      stack: "Total",
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        show: true,
        color: "#68BBC4",
        formatter: function (params) {
          return params.value + "%";
        },
      },
    },
  ],
};
export const sports_art_student = {
  title: {
    text: "体艺2+1达成率分布（2022-2023学年）",
    bottom: "5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "item",
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  series: [
    {
      name: "体艺2+1达成率分布",
      type: "pie",
      radius: "80%",
      center: ["50%", "40%"],
      data: [
        { value: 0, name: "一年级" },
        { value: 0, name: "二年级" },
        { value: 0, name: "三年级" },
        { value: 0, name: "四年级" },
        { value: 0, name: "五年级" },
        { value: 0, name: "六年级" },
        { value: 0, name: "七年级" },
        { value: 0, name: "八年级" },
        { value: 0, name: "九年级" },
      ],
      label: {
        show: true,
        textStyle: {
          color: "#444444",
          fontSize: 10,
          lineHeight: 14,
        },
        formatter: function (record) {
          return `${record.name} \n ${record.value}%`;
        },
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};
export const sports_art_studentAll = {
  title: {
    text: "体艺2+1达成率学校TOP(2022-2023学年)",
    bottom: "5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    formatter: function (record) {
      return `${record[0].name} \n ${record[0].value}人`;
    },
  },
  grid: {
    top: "5%",
    left: "2%",
    right: "6%",
    bottom: "15%",
    containLabel: true,
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  xAxis: {
    type: "value",
    boundaryGap: [0, 0.01],
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  yAxis: {
    type: "category",
    data: [
      "xxx学校1",
      "xxx学校2",
      "xxx学校3",
      "xxx学校4",
      "xxx学校5",
      "xxx学校6",
      "xxx学校7",
      "xxx学校8",
      "xxx学校9",
      "xxx学校10",
    ],
  },
  series: [
    {
      name: "大课间出勤率学校TOP10（2022-2023学年）",
      type: "bar",
      label: {
        show: true,
        position: "right",
        color: "#226BFF",
        formatter: function (record) {
          return `${record.value}%`;
        },
      },
      data: [1, 2, 3, 5, 6, 7, 8, 9, 10, 11],
    },
  ],
};
export const club_student = {
  title: {
    text: "俱乐部评分占比（2022-2023学年）",
    bottom: "5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "item",
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  series: [
    {
      name: "俱乐部评分占比",
      type: "pie",
      radius: "80%",
      center: ["50%", "45%"],
      data: [
        { value: 0, name: "优秀" },
        { value: 0, name: "良好" },
        { value: 0, name: "及格" },
        { value: 0, name: "不及格" },
      ],
      label: {
        show: true,
        textStyle: {
          color: "#444444",
          fontSize: 10,
          lineHeight: 14,
        },
        formatter: function (record) {
          return `${record.name} \n ${record.value}%`;
        },
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

// 详情
export const evaluation_student = {
  radar: {
    // shape: 'circle',
    indicator: [
      { name: "运动能力", max: 120 },
      { name: "体育知识", max: 120 },
      { name: "运动行为", max: 120 },
      { name: "体质健康", max: 120 },
    ],
    name: {
      rich: {
        a: {
          color: "#444",
          lineHeight: 10,
        },
      },
      formatter: (a, b) => {
        // return `{a|${a}}\n{b|${b.max}%}`;
        return `{a|${a}}`;
      },
    },
  },
  color: [
    "#5D77F9",
    "#A8DBCE",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  series: [
    {
      name: "运动行为",
      type: "radar",
      data: [],
    },
  ],
};

export const physical = {
  radar: {
    // shape: 'circle',
    indicator: [],
    name: {
      rich: {
        a: {
          color: "#444",
          lineHeight: 10,
        },
      },
      formatter: (a, b) => {
        // return `{a|${a}}\n{b|${b.max}%}`;
        return `{a|${a}}`;
      },
    },
  },
  color: [
    "#7AC3CB",
    "#2478F2",
    "#5D77F9",
    "#A8DBCE",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  series: [
    {
      name: "耐力",
      type: "radar",
      data: [],
    },
  ],
};

export const national_health = {
  legend: {
    top: "5%",
  },
  grid: {
    top: "10%",
    left: "4%",
    right: "4%",
    bottom: "5%",
    containLabel: true,
  },
  tooltip: {
    trigger: "axis",
    formatter: function (record) {
      return `
      ${record[0].name}</br> ${record[0].seriesName} \n  ${record[0].value[1]}分</br> ${record[1].seriesName} \n ${record[1].value[2]}分`;
    },
  },
  color: [
    "#5D77F9",
    "#A8DBCE",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  dataset: {
    source: [
      ["product", "2022-2023学年", "2021-2022学年"],
      ["BMI", 0, 0],
      ["肺活量", 0, 0],
      ["50米跑", 0, 0],
      ["1分钟仰卧起坐", 0, 0],
      ["1分钟跳绳", 0, 0],
      ["坐位体前屈", 0, 0],
    ],
  },
  xAxis: {
    type: "category",
    axisLabel: {
      interval: 0,
    },
  },
  yAxis: {
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  // Declare several bar series, each will be mapped
  // to a column of dataset.source by default.
  series: [
    {
      type: "bar",
      label: {
        show: true,
        position: "top",
        color: "#5D77F9",
        formatter: (value) => {
          return value.value[1] + "分";
        },
      },
    },
    {
      type: "bar",
      label: {
        show: true,
        position: "top",
        color: "#A8DBCE",
        formatter: (value) => {
          return value.value[2] + "分";
        },
      },
    },
  ],
};

export const national_body = {
  legend: {
    top: "5%",
  },
  grid: {
    top: "10%",
    left: "4%",
    right: "4%",
    bottom: "10%",
    containLabel: true,
  },
  tooltip: {
    trigger: "axis",
    formatter: function (record) {
      return `
    ${record[0].name}</br> ${record[0].seriesName} \n  ${record[0].value[1]}分</br> ${record[1].seriesName} \n ${record[1].value[2]}分`;
    },
  },
  color: [
    "#5D77F9",
    "#A8DBCE",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  dataset: {
    source: [
      ["product", "2022-2023学年", "2021-2022学年"],
      ["身高", 0, 0],
      ["体重", 0, 0],
      ["高低肩", 0, 0],
      ["脊柱侧弯", 0, 0],
      ["骨盆前倾", 0, 0],
      ["驼背", 0, 0],
    ],
  },
  xAxis: {
    type: "category",
    axisLabel: {
      interval: 0,
    },
  },
  yAxis: {
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  // Declare several bar series, each will be mapped
  // to a column of dataset.source by default.
  series: [
    {
      type: "bar",
      label: {
        show: true,
        position: "top",
        color: "#5D77F9",
        formatter: (value) => {
          return value.value[1] + "分";
        },
      },
    },
    {
      type: "bar",
      label: {
        show: true,
        position: "top",
        color: "#A8DBCE",
        formatter: (value) => {
          return value.value[2] + "分";
        },
      },
    },
  ],
};

export const national_sports = {
  legend: {
    top: "5%",
  },
  grid: {
    top: "10%",
    left: "4%",
    right: "4%",
    bottom: "10%",
    containLabel: true,
  },
  tooltip: {
    trigger: "axis",
    formatter: function (record) {
      return `
    ${record[0].name}</br> ${record[0].seriesName} \n  ${record[0].value[1]}分</br> ${record[1].seriesName} \n ${record[1].value[2]}分`;
    },
  },
  color: [
    "#5D77F9",
    "#A8DBCE",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  dataset: {
    source: [
      ["product", "2022-2023学年", "2021-2022学年"],
      ["安全应急演练", 0, 0],
      ["生长发育与青春期保健", 0, 0],
      ["健康行为", 0, 0],
      ["生活方式", 0, 0],
      ["疾病与突发事件", 0, 0],
      ["心理健康", 0, 0],
    ],
  },
  xAxis: {
    type: "category",
    axisLabel: {
      interval: 0,
    },
  },
  yAxis: {
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  // Declare several bar series, each will be mapped
  // to a column of dataset.source by default.
  series: [
    {
      type: "bar",
      label: {
        show: true,
        position: "top",
        color: "#5D77F9",
        formatter: (value) => {
          return value.value[1] + "分";
        },
      },
    },
    {
      type: "bar",
      label: {
        show: true,
        position: "top",
        color: "#A8DBCE",
        formatter: (value) => {
          return value.value[2] + "分";
        },
      },
    },
  ],
};
export const box_sorce = {
  legend: {
    top: "5%",
  },
  grid: {
    top: "15%",
    left: "4%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  tooltip: {
    trigger: "axis",
    formatter: function (record) {
      return `
    ${record[0].name}</br> ${record[0].seriesName} \n  ${record[0].value[1]}分</br> ${record[1].seriesName} \n ${record[1].value[2]}分`;
    },
  },
  color: [
    "#5D77F9",
    "#A8DBCE",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  dataset: {
    source: [
      ["product", "2022-2023学年", "2021-2022学年"],
      ["得分", 0, 0],
    ],
  },
  xAxis: {
    type: "value",
    axisLabel: {
      interval: 0,
    },
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  yAxis: {
    type: "category",
    // splitLine: {
    //   //坐标轴在grid区域中的分割线
    //   show: false,
    // },
    // axisLine: {
    //   show: false,
    // },
  },
  // Declare several bar series, each will be mapped
  // to a column of dataset.source by default.
  series: [
    {
      type: "bar",
      label: {
        show: true,
        position: "right",
        color: "#5D77F9",
        formatter: (value) => {
          return value.value[1] + "分";
        },
      },
    },
    {
      type: "bar",
      label: {
        show: true,
        position: "right",
        color: "#A8DBCE",
        formatter: (value) => {
          return value.value[2] + "分";
        },
      },
    },
  ],
};

export const box_completed = {
  legend: {
    top: "5%",
  },
  grid: {
    top: "15%",
    left: "4%",
    right: "5%",
    bottom: "3%",
    containLabel: true,
  },
  tooltip: {
    trigger: "axis",
    formatter: function (record) {
      return `
    ${record[0].name}</br> ${record[0].seriesName} \n  ${record[0].value[1]}%</br> ${record[1].seriesName} \n ${record[1].value[2]}%`;
    },
  },
  color: [
    "#5D77F9",
    "#A8DBCE",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  dataset: {
    source: [
      // ["product", "2022-2023学年", "2021-2022学年"],
      // ["完成率", 0, 0],
    ],
  },
  xAxis: {
    type: "value",
    axisLabel: {
      interval: 0,
    },
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  yAxis: {
    type: "category",
    // splitLine: {
    //   //坐标轴在grid区域中的分割线
    //   show: false,
    // },
    // axisLine: {
    //   show: false,
    // },
  },
  // Declare several bar series, each will be mapped
  // to a column of dataset.source by default.
  series: [
    {
      type: "bar",
      label: {
        show: true,
        position: "right",
        color: "#5D77F9",
        formatter: (value) => {
          return value.value[1] + "%";
        },
      },
    },
    {
      type: "bar",
      label: {
        show: true,
        position: "right",
        color: "#A8DBCE",
        formatter: (value) => {
          return value.value[2] + "%";
        },
      },
    },
  ],
};

export const national_sports_behavior = {
  legend: {
    top: "5%",
  },
  grid: {
    top: "10%",
    left: "4%",
    right: "4%",
    bottom: "10%",
    containLabel: true,
  },
  tooltip: {
    trigger: "axis",
    formatter: function (record) {
      return `
    ${record[0].name}</br> ${record[0].seriesName} \n  ${record[0].value[1]}分</br> ${record[1].seriesName} \n ${record[1].value[2]}分`;
    },
  },
  color: [
    "#5D77F9",
    "#A8DBCE",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  dataset: {
    source: [
      ["product", "2022-2023学年", "2021-2022学年"],
      ["9月", 0, 0],
      ["10月", 0, 0],
      ["11月", 0, 0],
      ["12月", 0, 0],
      ["1月", 0, 0],
      ["2月", 0, 0],
      ["3月", 0, 0],
      ["4月", 0, 0],
      ["5月", 0, 0],
      ["6月", 0, 0],
    ],
  },
  xAxis: {
    type: "category",
    axisLabel: {
      interval: 0,
    },
  },
  yAxis: {
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  // Declare several bar series, each will be mapped
  // to a column of dataset.source by default.
  series: [
    {
      type: "bar",
      label: {
        show: true,
        position: "top",
        color: "#5D77F9",
        formatter: (value) => {
          return value.value[1] + "分";
        },
      },
    },
    {
      type: "bar",
      label: {
        show: true,
        position: "top",
        color: "#A8DBCE",
        formatter: (value) => {
          return value.value[2] + "分";
        },
      },
    },
  ],
};
export const box_sorce_behavior = {
  legend: {
    top: "5%",
  },
  grid: {
    top: "15%",
    left: "4%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  tooltip: {
    trigger: "axis",
    formatter: function (record) {
      return `
    ${record[0].name}</br> ${record[0].seriesName} \n  ${record[0].value[1]}分</br> ${record[1].seriesName} \n ${record[1].value[2]}分`;
    },
  },
  color: [
    "#5D77F9",
    "#A8DBCE",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  dataset: {
    source: [
      ["product", "2022-2023学年", "2021-2022学年"],
      ["得分", 0, 0],
    ],
  },
  xAxis: {
    type: "value",
    axisLabel: {
      interval: 0,
    },
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  yAxis: {
    type: "category",
    // splitLine: {
    //   //坐标轴在grid区域中的分割线
    //   show: false,
    // },
    // axisLine: {
    //   show: false,
    // },
  },
  // Declare several bar series, each will be mapped
  // to a column of dataset.source by default.
  series: [
    {
      type: "bar",
      label: {
        show: true,
        position: "right",
        color: "#5D77F9",
        formatter: (value) => {
          return value.value[1] + "分";
        },
      },
    },
    {
      type: "bar",
      label: {
        show: true,
        position: "right",
        color: "#A8DBCE",
        formatter: (value) => {
          return value.value[2] + "分";
        },
      },
    },
  ],
};

export const box_completed_behavior = {
  legend: {
    top: "5%",
  },
  grid: {
    top: "15%",
    left: "4%",
    right: "5%",
    bottom: "3%",
    containLabel: true,
  },
  tooltip: {
    trigger: "axis",
    formatter: function (record) {
      return `
    ${record[0].name}</br> ${record[0].seriesName} \n  ${record[0].value[1]}%</br> ${record[1].seriesName} \n ${record[1].value[2]}%`;
    },
  },
  color: [
    "#5D77F9",
    "#A8DBCE",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  dataset: {
    source: [
      // ["product", "2022-2023学年", "2021-2022学年"],
      // ["完成率", 0, 0],
    ],
  },
  xAxis: {
    type: "value",
    axisLabel: {
      interval: 0,
    },
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  yAxis: {
    type: "category",
    // splitLine: {
    //   //坐标轴在grid区域中的分割线
    //   show: false,
    // },
    // axisLine: {
    //   show: false,
    // },
  },
  // Declare several bar series, each will be mapped
  // to a column of dataset.source by default.
  series: [
    {
      type: "bar",
      label: {
        show: true,
        position: "right",
        color: "#5D77F9",
        formatter: (value) => {
          return value.value[1] + "%";
        },
      },
    },
    {
      type: "bar",
      label: {
        show: true,
        position: "right",
        color: "#A8DBCE",
        formatter: (value) => {
          return value.value[2] + "%";
        },
      },
    },
  ],
};
